/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function n(n) {
  return n;
}
function r(r) {
  return n(1e3 * r);
}
function t(n) {
  return n;
}
function u(n) {
  return t(.001 * n);
}
function e() {
  return n(performance.now());
}
export { n as Milliseconds, t as Seconds, r as millisecondsFromSeconds, e as now, u as secondsFromMilliseconds };